import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { StudioSettingsContext } from './Context';

const defaultHeight = window.innerHeight - 165;

const defaultValue = {
	value: {
		label: 'Default',
		leftMenu: { favoriteTab: '', isOpen: false, isCollapsed: false, x: 0, y: 64 },
		rightMenu: { favoriteTab: 'people', isOpen: false, isCollapsed: false, x: 0, y: 64 },
		midRightMenu: { favoriteTab: '', isOpen: false, isCollapsed: false, x: 0, y: 64 },
		peopleMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		inviteMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		emailShareMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		smsShareMenu: { favoriteTab: '', isOpen: false, x: 0, y: 64, width: 440, height: defaultHeight },
		linkShareMenu: { favoriteTab: '', isOpen: false, x: 0, y: 64, width: 440, height: defaultHeight },
		groupsShareMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		qrShareMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		pollMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		auctionMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		drawMenu: { favoriteTab: '', isOpen: false, drawMenu: true, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		toolsMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		elementsMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryVideosMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryAudiosMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryGFXMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryImagesMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryPlaylistMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryStreamsMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		chatMenu: { favoriteTab: '', isOpen: false, chatMenu: true, isExpanded: false, x: 0, y: 64, width: 440, height: (defaultHeight + 47) },
		mediaManager: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		beeyouUsersMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		libraryScenesMenu: { favoriteTab: '', isOpen: false, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
		participantDrawMenu: { favoriteTab: '', isOpen: false, participantDrawMenu: true, x: 0, y: 64, width: 440, height: defaultHeight },
		peopleMenuWithInvite: { favoriteTab: '', isOpen: false, fromHeader: true, isExpanded: false, x: 0, y: 64, width: 440, height: defaultHeight },
	},
};

export const fullScreenMenuItems = Object.keys(defaultValue.value).filter((key) => key !== 'label'
  && key !== 'leftMenu'
  && key !== 'rightMenu'
  && key !== 'midRightMenu');

const updateMenuHeight = (menus, newHeight) => (
	Object.entries(menus).reduce((acc, [key, menu]) => ({
		...acc,
		[key]: menu.x === 0 && menu.y === 64 ? {
			...menu,
			height: key === 'chatMenu' ? newHeight + 50 : newHeight,
		} : menu,
	}), {})
);

export const StudioSettingsProvider = ({ children }) => {
	const [studioSettings, setStudioSettings] = useState(defaultValue);
	const [previousOpenMenu, setPreviousOpenMenu] = useState(null);
	const [isSubmenuOpened, setIsSubmenuOpened] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			const newHeight = window.innerHeight - 165;
			setStudioSettings((prev) => ({
				value: updateMenuHeight(prev.value, newHeight),
			}));
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const context = useMemo(() => {
		const isMenuOpened = fullScreenMenuItems.some(
			(menuKey) => studioSettings.value[menuKey]?.isOpen,
		);

		const activeMenu = fullScreenMenuItems.find(
			(menuKey) => studioSettings.value[menuKey]?.isOpen,
		);

		const isMenuExpanded = activeMenu ? studioSettings.value[activeMenu]?.isExpanded : false;
		return {
			handleChangeStudioSettings: (data) => {
				setStudioSettings({
					value: {
						...data,
					},
				});
			},
			handleMenuPosition: (menuKey, data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						[menuKey]: {
							...prev.value[menuKey],
							...data,
						},
					},
				}));
			},
			handleChangeRightMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						rightMenu: {
							...prev.value.rightMenu,
							...data,
						},
					},
				}));
			},
			handleChangeMidRightMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						midRightMenu: {
							...prev.value.midRightMenu,
							...data,
						},
					},
				}));
			},
			handleChangeLeftMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						leftMenu: {
							...prev.value.leftMenu,
							...data,
						},
					},
				}));
			},
			handleExpandMenu: (menuKey) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						[menuKey]: {
							...prev.value[menuKey],
							isExpanded: !prev.value[menuKey]?.isExpanded,
						},
					},
				}));
			},
			handleChangeMenuItem: (menuKey, data) => {
				if (data.isOpen) {
					const currentOpenMenu = fullScreenMenuItems.find(
						(key) => studioSettings.value[key]?.isOpen,
					);
					setPreviousOpenMenu(currentOpenMenu || null);
				} else {
					setPreviousOpenMenu(null);
				}
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						...(fullScreenMenuItems.includes(menuKey)
							? fullScreenMenuItems.reduce((acc, key) => ({
								...acc,
								[key]: key === menuKey
									? {
										...prev.value[key],
										...data,
										isOpen: data.isOpen !== undefined
											? data.isOpen
											: !prev.value[key].isOpen,
									}
									: {
										...prev.value[key],
										// eslint-disable-next-line no-nested-ternary
										isOpen: window.innerWidth >= 1280
											? (prev.value[key].x === 0 && prev.value[key].y === 64
												? false
												: prev.value[key].isOpen)
											: false,
									},
							}), {})
							: { [menuKey]: { ...prev.value[menuKey], ...data } }
						),
					},
				}));
			},
			studioSettings,
			defaultValue,
			isMenuOpened,
			previousOpenMenu,
			isMenuExpanded,
			setIsSubmenuOpened,
			isSubmenuOpened,
		};
	}, [studioSettings, previousOpenMenu, isSubmenuOpened]);

	return (
		<StudioSettingsContext.Provider value={context}>
			{children}
		</StudioSettingsContext.Provider>
	);
};

StudioSettingsProvider.propTypes = {
	children: PropTypes.node,
};

StudioSettingsProvider.defaultProps = {
	children: undefined,
};
